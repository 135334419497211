jQuery.fn.dataTableExt.oApi.spin = function (oSettings) {
  
    this.bind('processing', function (e, settings, bShow) {

            if (oSettings.oFeatures.bProcessing) {
                var opt = bShow ? 'large' : false;
                var an = oSettings.aanFeatures.r;
                for (var i = 0, iLen = an.length ; i < iLen ; i++) {
                    $(an[i]).spin(opt);
                }
            }

    });
}